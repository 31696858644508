export default {
	name: "global",
	store() {
		return {
			isBannerVisible: true,
			isMobileMenuVisible: false,
			isMinicartVisible: false,
			isPredictiveSearchVisible: false,
			isWindowScrolled: false,
			cart: null,
			init() {
				window.addEventListener(
					"scroll",
					this.onWindowScrollHandler.bind(this),
				);

				this.initLiquidAJaxCart();
			},
			get bodyClasses() {
				const classes = [];

				if (this.isMobileMenuVisible) {
					classes.push("mobile-menu-visible");
				}

				return classes || "";
			},
			openMobileMenu() {
				this.isMobileMenuVisible = true;
			},
			closeMobileMenu() {
				this.isMobileMenuVisible = false;
			},
			toggleMobileMenu() {
				this.isMobileMenuVisible = !this.isMobileMenuVisible;
			},
			initLiquidAJaxCart() {
				document.addEventListener("liquid-ajax-cart:request-end", (event) => {
					const { requestState, cart, previousCart, sections } = event.detail;

					if (requestState.requestType === "add") {
						if (requestState.responseData?.ok) {
							this.isMinicartVisible = true;
						}
					}

					this.cart = cart;
				});
			},
			onWindowScrollHandler() {
				const isScrolled = window.scrollY > 0;

				const section = this.$refs.mainBanner;
				const rect = section.getBoundingClientRect();
				this.isBannerVisible =
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <=
						(window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <=
						(window.innerWidth || document.documentElement.clientWidth);

				this.isWindowScrolled = isScrolled;
				document.body.classList[isScrolled ? "add" : "remove"]("scrolled");
			},
		};
	},
};
